<template>
  <div class="card w-lg-500px text-center">
    <div class="card-body fs-3">
      <h1 class="mb-8">Page de contact</h1>
      <p>
        Pour toutes questions, nous vous invitons à envoyer un mail à l'adresse
        : contact&#64;agribest&#46;fr
      </p>
      <a class="btn btn-primary my-6" href="mailto:contact@agribest.fr">
        Envoyer un mail
      </a>
    </div>
  </div>
</template>
